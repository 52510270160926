/**
 *  Microtaskers
 *  Nom: ANDRIARILALAO Johny Lino
 *  Email: johny.andriarilalao@gmail.com
 */

import { GoogleOAuthProvider } from '@react-oauth/google'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import Main from 'apps/main'
import React from 'react'
import './App.css'

const App: React.FC = () => {
  const queryClient = new QueryClient()

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID ?? ''} >
      <QueryClientProvider client={queryClient}>
        <Main />
      </QueryClientProvider>
    </GoogleOAuthProvider>
  )
}

export default App
