/**
 *  Microtaskers
 *  Nom: ANDRIARILALAO Johny Lino
 *  Email: johny.andriarilalao@gmail.com
 */

import AppRoutes from 'routes/AppRoutes'

function Main() {
  return (
    <>
      <AppRoutes />
    </>
  )
}

export default Main
