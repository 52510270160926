/**
 *  Microtaskers
 *  Nom: ANDRIARILALAO Johny Lino
 *  Email: johny.andriarilalao@gmail.com
 */

import { Suspense, PropsWithChildren, FC } from 'react'
export const SuspenseView: FC<PropsWithChildren> = ({ children }) => {
  return <Suspense>{children}</Suspense>
}
